import React, { Fragment } from "react";
import "./index.scss";

import cx from "classnames";

import logo from "../../assets/images/logo.png";
import ytb from "../../assets/images/ytb.png";
import ig from "../../assets/images/ig.png";
import fb from "../../assets/images/fb.png";

function Header({ isAnimated, isFooter }) {
  const socials = [
    {
      url: "https://www.facebook.com/nenormalninet/",
      image: fb
    },
    {
      url: "https://www.instagram.com/nenormalniofficial",
      image: ig
    },
    {
      url: "https://www.youtube.com/channel/UCKBBbzQL_TyXtTF3WSE5m0g",
      image: ytb
    }
  ];

  const headerClasses = cx({
    Header: true,
    "Header--animated": isAnimated,
    "Header--footer": isFooter
  });

  const renderSocial = () => {
    return socials.map((item, index) => {
      return (
        <a
          target="blank"
          rel="noopener noreferrer"
          key={index}
          className="Header-social-link"
          href={item.url}
        >
          <div>
            <img
              className="Header-social-image"
              src={item.image}
              alt="Social media"
            />
          </div>
        </a>
      );
    });
  };

  const renderButton = () => {
    return (
      <a
        href="https://www.youtube.com/watch?v=-yYDZ0sGLxE&list=PLh-u07aFZqfMX6g1rrVL_mh9z9i1U7kt-"
        target="_blank"
        className="button"
        rel="noopener noreferrer"
      >
        Poslušajte album
      </a>
    );
  };

  const renderContact = () => {
    return (
      <Fragment>
        <a className="Header-contact-link" href="tel:+381642331184">
          +381 64 233 1184
        </a>
        <a
          target="blank"
          rel="noopener noreferrer"
          className="Header-contact-link"
          href="mailto:nenormalnimejl@gmail.com"
        >
          nenormalnimejl@gmail.com
        </a>
      </Fragment>
    );
  };

  return (
    <div className={headerClasses}>
      <div className="Header-social">
        {renderSocial()}
        {renderButton()}
      </div>
      <img className="Header-logo" src={logo} alt="(ne)normalni logo" />
      <div className="Header-contact">{renderContact()}</div>
      <div className="Header-mobile">
        <div className="Header-mobile-contact">{renderContact()}</div>
        <div className="Header-mobile-social">
          {renderSocial()}
          {renderButton()}
        </div>
      </div>
    </div>
  );
}

export default Header;
