import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "../views/Home";
import DivljanBitef from "../views/Koncerti/DivljanBitef";
import DivljanPromenada from "../views/Koncerti/DivljanPromenada";

const publicPath = "/";

export const routeCodes = {
  HOME: publicPath,
  BITEF_DIVLJAN: `${publicPath}koncerti/divljan-bitef`,
  PROMENADA_DIVLJAN: `${publicPath}koncerti/divljan-promenada`,
};

export default () => (
  <Switch>
    <Route exact path={routeCodes.HOME} component={Home} />
    <Route exact path={routeCodes.BITEF_DIVLJAN} component={DivljanBitef} />
    <Route
      exact
      path={routeCodes.PROMENADA_DIVLJAN}
      component={DivljanPromenada}
    />
    <Route component={Home} />
  </Switch>
);
