import React from "react";

import "../../../App.scss";

function DivljanPromenada() {
  return (
    <div className="Koncert">
      <p>
        U ponedeljak, 5.7., (ne)normalni će izvesti koncert u čast Vlade
        Divljana i to u na krovu Promenade u Novom Sadu, sa početkom u 20
        časova.
        <br />
        Karte za taj događaj možete kupiti klikom na{" "}
        <a
          className="Koncert-karta button"
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.gigstix.com/sve-ulaznice/6-koncerti/3242-vlada-divljana"
        >
          kupi kartu
        </a>
        .
        <br />
        Više o koncertu možete pronaći na samom{" "}
        <a
          href="https://www.facebook.com/events/452838022551542/"
          target="_blank"
          rel="noopener noreferrer"
        >
          eventu
        </a>
        .
      </p>
      <div className="Koncert-fb">
        <div
          className="fb-page"
          data-href="https://www.facebook.com/nenormalninet"
          data-tabs="events"
          data-small-header="true"
          data-adapt-container-width="true"
          data-hide-cover="true"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/nenormalninet"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/nenormalninet">(ne)normalni</a>
          </blockquote>
        </div>
      </div>
      <div className="Koncert-iframe">
        <iframe
          src="https://www.youtube.com/embed/Cq9BE7bg_H0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
}

export default DivljanPromenada;
