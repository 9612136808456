import React from "react";

import "../../App.scss";
import YoutubeIframe from "../../components/YoutubeIframe";
import Hero from "../../components/Hero";

function Home() {
  return (
    <div className="Home">
      <Hero />
      <div className="Desc">
        <div className="Description">
          Naša nova pesma, novi spot... <br /> <br />
          <span className="Yellow">
            Našao sam jedan nakošeni gradic, ulicu od kocke <br />
            i izlog od radnje iz deve`sto koje <br />
            u izlogu pera, četkice i boje <br />
            škljocnuo sam okom... da ostane slika <br />
            da ti izlog prepricavam... da te uspavam <br />
          </span>
        </div>
        <div className="Iframe">
          <YoutubeIframe />
        </div>
      </div>
    </div>
  );
}

export default Home;
