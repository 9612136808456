import React from "react";

function YoutubeIframe() {
  return (
    <iframe
      title="Youtube iframe"
      width="100%"
      src="https://www.youtube.com/embed/EBJ25_Q7F7Q"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
}

export default YoutubeIframe;
