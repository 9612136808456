import React, { useState, useEffect } from "react";
import Header from "./components/Header";

import "./App.scss";
import Routes from "./config/routes";

function App() {
  const [isHeaderAnimated, setIsHeaderAnimated] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    const scrollY = window.pageYOffset;
    const SCROLL_TRESHOLD = 100;
    setIsHeaderAnimated(scrollY > SCROLL_TRESHOLD);
  };

  return (
    <div className="App">
      <Header isAnimated={isHeaderAnimated} />
      <Routes />
      <Header isFooter />
    </div>
  );
}

export default App;
